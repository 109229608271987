#app {
  background-color: #ffffff;
  width: 300px;
  padding: 36px;
  margin: auto;
  margin-top: 100px;
  border-radius: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.2);
  align-self: center;
}
#app1 {
  background-color: #ffffff;
  width: 300px;
  padding: 36px;
  margin: auto;
  margin-top: 100px;
  border-radius: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  align-self: center;
}

#leftbox {
  position: absolute;
  left: 60px;
  top: 288px;
}

#rightbox {
  position: absolute;
  right: 60px;
  top: 288px;
}

.photo1 {
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
}
